.market-opportunity-landing-page {
    $lg: 1140px;

    &__introduction {
        text-align: center;
        text-wrap: balance;
        margin-inline: auto;
        max-width: $lg;

        p {
            color: $black;
        }
    }

    &__newsletter-container {
        background-color: #e4dffc;
        padding-top: $gap-large;
        padding-bottom: $gap-large;

        &--row-container {
            row-gap: $gap-large;
        }

        &--image-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &--image {
                height: 62px;
            }
        }

        &--button-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            // This is to limit the button width because otherwise it went to the full length of the display in tablet and mobile
            max-width: 400px;
        }
    }
}
