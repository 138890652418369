.text-field {
  display: flex;
  flex-direction: column;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    select {
      appearance: none;
    }
  }

  &__select-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: $primary;
    }
  }

  &__label {
    position: absolute;
    left: 1rem;
    top: 0.5rem;

    transition: all 0.25s ease-in;
    opacity: 0;
    visibility: hidden;

    text-transform: uppercase;
    font-size: 0.75rem;
    color: #666;
  }

  &__label:has(+ .text-field__input:not(:placeholder-shown)) {
    opacity: 1;
    visibility: visible;
  }

  &__input {
    background-color: $bf-light-gray;
    border: 1px solid transparent;
    width: 100%;

    font-size: 1rem;
    line-height: 1.5;
    color: $primary;
    padding: 2rem 1rem 0;

    &::placeholder {
      text-transform: uppercase;
    }

    &:disabled {
      color: rgba($primary, 0.5);
      cursor: not-allowed;
    }
  }

  &__required {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    color: red;
  }

  &__input:not(:placeholder-shown) + .text-field__required {
    display: none;
  }

  &__error {
    color: red;
    font: inherit;
    letter-spacing: inherit;
    word-spacing: inherit;
    margin-top: 0.25rem;
  }
}
