.headlessui-popover {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
  }

  &__panel {
    background-color: white;
    display: flex;
    flex-direction: column;

    border: 1px solid $primary;

    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;

    &[data-closed] {
      transform: translateY(-1rem);
      opacity: 0;
    }
  }
}
