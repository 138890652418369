.toast {
  background-color: $light-blue;
  color: $black;
  border: 1px solid $light-blue;
  box-shadow: 0 2 4px 0 rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  gap: $gap-small;
  border-radius: 0;

  &__description {
    color: $text-muted;
  }

  &__cancel-button {
    background-color: $text-muted;
    color: $white;
    // group-[.toast]:bg-muted group-[.toast]:text-muted-foreground
  }

  &--info {
    background-color: $light-blue;
    // color: $white;
  }

  &.sa-toast {
    display: grid;
    grid-template-columns: 2rem 1fr auto;
    font-size: 1rem;

    .sa-toast__icon {
      height: auto;
      width: 1.5rem;
      display: block;
      color: $primary;
    }

    .sa-toast__manage-button {
      @include font-primary-regular();
      text-decoration: underline;
      color: $black;
      font-size: 1rem;
    }
  }
}
