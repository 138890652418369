.error-boundary {
  $md: 640px;
  max-width: $md;
  background-color: $bf-light-gray;
  margin-inline: auto;
  border-top: 10px solid $danger;
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: $gap-large;
  align-items: center;

  h4 {
    font-size: $heading-h4;
    line-height: $line-height-h4;
    text-align: center;
    text-transform: none;
    margin: 0;
    color: $danger;
    @include font-primary-regular();
  }
}
