.mo-search-area {
  display: flex;
  flex-direction: column;
  gap: $gap-medium;

  h2 {
    margin-bottom: 0;
    font-size: $heading-h3;
    text-transform: initial;
  }

  fieldset legend {
    color: $bf-blue;
  }

  label {
    color: $bf-blue;

    font-size: $text-1;
    font-style: normal;
    font-weight: 400;
    line-height: $line-height-t1;
  }

  button {
    width: 100%;
  }

  &__input {
    font-size: $text-2;
    padding: $gap-medium $gap-small;
  }

  &__filter-checkbox {
    display: flex;
    align-items: center;
    gap: $gap-small;

    input[type="checkbox"] {
      position: relative;
      opacity: 1;
      height: 26px;
      width: 26px;
      flex-shrink: 0;
      align-self: flex-start;

      accent-color: $bf-blue;
    }
  }
}
