.notification-panel {
  @include scrollbar-blue();
  max-width: 48rem;

  &__header {
    padding: $gap-medium;

    h3 {
      margin: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-height: 30dvh;
    overflow-y: auto;

    p {
      color: $primary;
    }
  }

  &__empty {
    padding: $gap-medium;

    p {
      margin: 0;
    }
  }

  &__footer {
    padding: $gap-medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $gap-large;
  }

  .notification-card {
    position: relative;
    color: $primary;
    background-color: white;
    transition: all 0.3s ease;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gap-small;
    border: 1px solid transparent;
    border-top-color: $bf-light-gray;
    border-bottom-color: $bf-light-gray;

    &:hover,
    &:focus-within {
      background-color: $bf-light-gray;
    }

    &--unseen {
      background-color: $light-blue;

      &:hover,
      &:focus-within {
        background-color: rgba($light-blue, 0.5);
      }
    }

    p {
      margin: 0;
    }
    &__button {
      cursor: pointer;
      color: $primary;
      font: inherit;
      font-size: 1rem;

      width: 100%;
      margin: $gap-medium $gap-large;
      margin-right: $gap-xlarge;

      outline-color: $primary;

    &:hover,
      & :focus-visible {
        text-decoration: underline;
      }
    }

    &__date {
      display: block;
      margin-top: $gap-medium;
      font-size: 0.75rem;
      text-align: right;
    }

    &__unseen-button {
      all: unset;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      min-width: 1.75rem;
      min-height: 1.75rem;
      padding: $gap-small;
      display: grid;
      place-content: center;

      cursor: pointer;
      background-color: $light-blue;

      &:hover,
      &:focus {
        background-color: rgba($light-blue, 0.5);
      }

      &::after {
        content: "";
        display: block;
        width: 1rem;
        height: 1rem;
        background-color: $primary;
        border-radius: 50%;
      }

      &:focus {
        outline-offset: -2px !important;
      }
    }
  }
}

.notification-button {
  svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  .notification-badge {
    position: absolute;
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: red;
    border-radius: 50%;

    top: 0.25rem;
    right: 0.25rem;
  }
}
