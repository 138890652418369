.market-opportunity-page {
  p,
  li {
    color: $black;
  }

  &__header {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: $gap-large;

    &__content {
      &--no-image {
        grid-column: span 9;

        @include media-breakpoint-down(lg) {
          grid-column: span 12;
        }
      }

      &--with-image {
        grid-column: span 5;

        @include media-breakpoint-down(lg) {
          grid-column: span 12;
        }
        // Needed for mobile to show all content in small screens less than 370px
        @include media-breakpoint-down(sm) {
          grid-column: span 10;
        }
      }

      &__flag {
        margin-bottom: $gap-medium;
        display: flex;
        align-items: center;
        gap: $gap-small;

        svg,
        img {
          width: 1.375rem;
        }

        svg {
          aspect-ratio: 3/4;
        }

        span {
          font-size: $text-2;
          color: $black;
          font-weight: 700;
          line-height: $line-height-t2;
        }
      }
    }

    &__image {
      grid-column: 7/13;

      @include media-breakpoint-up(lg) {
        margin-right: -152px; // Overflow image outside of container width in desktop only.
        img {
          height: 592px; // TODO: Height specified in figma. If using 100% it goes to over 660px. Must confirm final value.
          object-fit: cover; // Cover the whole area and crop image if it does not fit.
          object-position: center;
          aspect-ratio: 4/3;
        }
      }

      @include media-breakpoint-down(lg) {
        order: -1;
        grid-column: span 12;
      }
    }
  }

  &__act-now {
    display: block;
    gap: $gap-large;

    > div {
      margin-bottom: $gap-large;
    }

    #interestedInSpinner {
      display: inline-block;
      vertical-align: middle;
      margin: 14px;
    }

    #interestedInThankYou {
      padding: $gap-large;
      background-color: #dbeefe;
    }

    #interestedInError {
      padding: $gap-large;
      background-color: #feeedb;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: $gap-large;
    margin-top: $gap-xlarge;

    &__content {
      grid-column: span 7;

      @include media-breakpoint-down(lg) {
        grid-column: span 12;
      }
      // Needed for mobile to show all content in small screens less than 370px
      @include media-breakpoint-down(sm) {
        grid-column: span 10;
      }

      ul {
        padding-left: 1.5rem;
        margin-bottom: $gap-medium;

        li {
          @include font-primary-regular();
          font-size: $text-2;
          font-style: normal;
          line-height: $line-height-t2;
        }
      }
    }

    &__search {
      grid-column: 9 / 13;

      @include media-breakpoint-down(lg) {
        grid-column: span 12;
      }
      // Needed for mobile to show all content in small screens less than 370px
      @include media-breakpoint-down(sm) {
        grid-column: span 10;
      }

      margin-bottom: $gap-large;
    }
  }

  &__author {
    &__body {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: $gap-large;
      row-gap: $gap-medium;

      &__image {
        grid-column: span 2;

        @include media-breakpoint-down(lg) {
          grid-column: span 6;
        }

        @include media-breakpoint-down(md) {
          grid-column: span 12;
        }
        // Needed for mobile to show all content in small screens less than 370px
        @include media-breakpoint-down(sm) {
          grid-column: span 10;
        }

        img {
          max-height: 20vh;
          object-fit: cover;
          object-position: center;
          width: 100%;
          aspect-ratio: 1/1;
        }
      }

      &__content {
        grid-column: span 10;

        @include media-breakpoint-down(lg) {
          grid-column: span 6;
        }

        @include media-breakpoint-down(md) {
          grid-column: span 12;
        }

        // Needed for mobile to show all content in small screens less than 370px
        @include media-breakpoint-down(sm) {
          grid-column: span 10;
        }

        color: $primary;

        &__profile-title {
          display: block;
          font-weight: 700;
          font-size: $text-3;
          line-height: $line-height-t3;
          margin-bottom: $gap-medium;
        }

        &__profile-name {
          margin-bottom: $gap-medium;
        }

        &__profile-contact {
          font-size: $text-3;
          line-height: $line-height-t3;
        }
      }
    }
  }

  &__metadata {
    margin: $gap-large 0 $gap-xxxlarge 0;
  }

  &__not-logged {
    text-align: center;
    margin: 1.5rem;
  }
}
