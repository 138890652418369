.page-elements {
  &__actions {
    margin-bottom: $gap-xlarge;

    &__search {
      margin-bottom: $gap-medium;
    }

    &__filters {
      display: flex;
      flex-wrap: wrap;
      gap: $gap-medium $gap-large;

      & > div {
        width: fit-content;
      }

      a {
        color: $primary;
      }
    }

    @include media-breakpoint-down(md) {
      &__filters {
        flex-direction: column;
        gap: $gap-medium;
        flex-wrap: nowrap;

        & > div {
          width: auto;
        }
      }
    }
  }

  &__results {
    display: flex;
    flex-direction: column;
    gap: $gap-large;
  }

  &__pagination {
    padding-bottom: 50px;
  }
}
