.opportunity-show-more {
  width: clamp(5vw, 360px, 75vw);
  display: grid;
  place-items: center;
  background-color: $bf-light-gray;
  margin-bottom: $gap-large;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  h3 {
    text-transform: none;
  }

  &:hover {
    box-shadow: 8px 8px 0px 0px #dbeefe;
    text-decoration: none;
  }
}
