.search-alert-card {
  padding: 1rem 2rem;

  display: grid;
  grid-template-columns: 1fr;
  gap: $gap-medium;

  @include media-breakpoint-up(sm) {
    grid-template-columns: 2fr auto;
  }

  &:nth-child(odd) {
    background-color: rgba($light-blue, 0.3);
  }

  &__title {
    font-size: 1rem;
    margin-bottom: 0.25rem;

    &.skeleton {
      width: 100%;
      height: 1.5rem;
      margin-bottom: 0.75rem;
    }
  }

  &__query-item {
    font-size: 1rem;
    line-height: 1.5;
    color: $primary;
    margin: 0;

    &.skeleton {
      width: 100%;
      height: 1.25rem;

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;

    &__buttons {
      display: flex;
      gap: $gap-medium;
      flex-wrap: nowrap;

      @include media-breakpoint-up(sm) {
        justify-content: flex-end;
      }
    }

    a {
      color: $primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
