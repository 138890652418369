@import "headlessui-listbox";
@import "headlessui-autocomplete";
@import "headlessui-dialog";
@import "headlessui-popover";
@import "shared";

.bf-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}
