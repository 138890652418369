.mo-header {
    margin-top: $gap-large;
    // This is also the hardcoded in the menu in bf.com. Changed to min height because of mobile problems in sso
    min-height: 100px;

    .signin {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .me-4 {
        color: $bf-blue;
        font-size: $text-3;
    }

    .signin button {
        width: 197px;
    }

    .breadcrumb-register {
        display: flex;
        align-items: center;
    }

    @include media-breakpoint-down(sm) {
        .breadcrumb-register {
            display: block;
        }
    }
}

// Onboarding / user preferences microservice styles. Needs to be global.
// The min height is there because the microservice uses position absolute.
// This causes the height to be 0 which makes the microservice go over the content.
#onboarding-service-container {
    min-height: 300px;
}

#mo-preference-close-button {
    &[aria-expanded="false"] {
        display: none;
    }

    &[aria-expanded="true"] {
        display: block;
    }
}
