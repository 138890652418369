.mo-actnow-action {
  display: flex;
  flex-direction: column;
  gap: $gap-large;

  &__act-now {
    display: flex;
    gap: $gap-small;
    align-items: center;
    color: $bf-pink;
    font-size: $text-1;
    line-height: $line-height-t1;
    font-weight: 700;

    &::before {
      content: "";
      display: inline-block;
      background-color: $bf-pink;
      width: 30px;
      height: 30px;
    }
  }
}
