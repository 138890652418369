.opportunity-card {
  width: clamp(5vw, 420px, 75vw);
  margin-bottom: $gap-large;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  gap: $gap-medium;

  &:hover,
  &:focus {
    box-shadow: 8px 8px 0px 0px #dbeefe;
    text-decoration: none;
  }
  &:focus-visible {
    outline: none;
  }

  h4,
  p {
    margin: 0;
  }

  &__title {
    text-wrap: balance;
    hyphens: auto;
    overflow-wrap: break-word;
    text-transform: none;
  }

  &__heading-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $gap-medium;

    & > div {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }

    img {
      @include skeleton-animation();
      width: 12rem;
      aspect-ratio: 4/3;
      object-fit: cover;
      background-position: center center;
    }

    &__act-now {
      display: flex;
      gap: $gap-small;
      align-items: center;
      color: $bf-pink;
      text-transform: uppercase;
      font-size: $text-1;
      line-height: $line-height-t1;
      overflow-wrap: break-word;
      hyphens: auto;

      &::before {
        content: "";
        display: inline-block;
        background-color: $bf-pink;

        flex-shrink: 0;
        width: 30px;
        height: 30px;
      }
    }

    &__opportunity-type {
      color: $black;
      font-size: $text-2;
      line-height: $line-height-t2;
    }
  }

  &__country {
    display: flex;
    align-items: center;
    gap: $gap-small;
    min-height: 2rem;

    &__flag {
      width: 2rem;
      height: 2rem;
    }
    &__name {
      font-size: 20px;
      line-height: 28px;

      color: $black;
    }
  }

  &__industry {
    color: $black;
    font-size: $text-2;
    line-height: $line-height-t2;

    span {
      color: $bf-blue;
    }
  }

  &--placeholder {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}
