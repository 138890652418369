.radio-field {
  display: flex;
  gap: $gap-small;
  align-items: center;

  &__label {
    font-size: 1.25rem;
    color: $primary;
  }
}

.radio-group {
  legend {
    font-size: 1.25rem;
    line-height: 1.5;
    color: $primary;
    margin-bottom: $gap-medium;
  }
  &__options {
    display: flex;
    gap: $gap-medium $gap-large;
    flex-wrap: wrap;
  }
}
