.category {
  &__heading {
    display: flex;
    gap: $gap-small;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: row;

    margin: 0 0 1rem;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &__title {
      margin: 0;
    }

    &__back-button {
      color: $bf-blue;
      font-size: $text-1;
      line-height: $line-height-t1;
      font-weight: 700;
    }
  }

  &__results {
    display: grid;
    gap: $gap-large;
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
    }

    .opportunity-card {
      width: auto;
    }
  }
}
