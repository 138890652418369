.headlessui-listbox {
  width: 100%;

  .headlessui-listbox-wrapper {
    position: relative;
  }

  &__button {
    width: 100%;
    display: inline-flex;
    align-items: center;

    justify-content: space-between;
    gap: $gap-xlarge;

    background-color: transparent;
    color: $primary;
    text-align: left;

    font-size: 18px;
    line-height: 30px;
    padding: 14px 48px 12px 32px;
    border: 2px solid $primary;
    outline: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out 0s,
      border-color 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;

    &::placeholder {
      color: $primary;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 30px;
    }

    span {
      display: block;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      scale: 1.5;
      height: 1rem;
    }
  }

  &__button-autocomplete {
    position: relative;

    &--icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      color: $primary;
      padding-right: 18px;

      svg {
        scale: 2;
        height: 1rem;
      }

      &--loading {
        animation: spin 5s linear infinite;
      }
    }
  }

  &__options {
    position: absolute;
    z-index: 1;
    max-height: 16rem;
    width: 100%;
    overflow-y: auto;
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    list-style: none;
    padding: 0;
  }

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $gap-small;

    padding: 14px 32px 12px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $primary;
      color: $white;
    }

    &--selected {
      background-color: $primary;
      color: $white;
    }
    &--active {
      background-color: $light-blue;
    }

    &--disabled {
      // cursor: not-allowed;
      color: $bf-gray;
      cursor: default;
      pointer-events: none;

      &:hover,
      &:focus {
        background-color: rgba(0, 45, 161, 0.5);
      }
    }

    &--clear-selection {
      background-color: rgba($light-blue, 0.5);
      color: $bf-blue;

      svg {
        height: 1.5rem;
      }
    }
  }

  &__transition {
    &--leave {
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      transition-duration: 100ms;
    }
    &--leave-from {
      opacity: 1;
    }
    &--leave-to {
      opacity: 0;
    }
  }

  &--disabled {
    .headlessui-listbox__button {
      color: $bf-gray;
      cursor: default;
      pointer-events: none;

      &::placeholder {
        color: $bf-gray;
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
