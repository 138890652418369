.mo-signin-page {
    &--row-gap {
        gap: $gap-large;
    }

    &--row-margins {
        margin-top: $spacer * 3; // equivalent to bootstrap class mt-5.
        padding: $spacer * 3; // equivalent to bootstrap class p-5.

        @include media-breakpoint-down(sm) {
            margin: $spacer; // equivalent to bootstrap class m-3.
            padding: $spacer 0 $spacer 0; // equivalent to bootstrap classes pt-3 pb-3 and rest 0.
        }
    }

    .col-lg-6 {
        @include media-breakpoint-up(lg) {
            width: 48%;
        }
    }

    &--virtu-logo {
        height: 50px;
    }
}
