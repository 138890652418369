.headlessui-dialog {
  @include scrollbar-blue();

  position: relative;
  z-index: 10;

  &:focus {
    outline: none;
  }

  &__backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__container {
    position: fixed;
    inset: 0;
    display: flex;

    width: 100vw; // Fall back for browsers that do not support dvw units
    width: 100dvw; // Primary width in dvh units. Read more: https://developer.mozilla.org/en-US/docs/Web/CSS/length#relative_length_units_based_on_viewport

    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  &__panel {
    max-width: 1200px;
    background-color: white;

    display: flex;
    flex-direction: column;
    max-height: 100%;

    min-height: 60vh;
    min-height: 60dvh;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $gap-medium;

    padding: 1rem;
    border-bottom: 1px solid $light-gray;

    @include media-breakpoint-up(sm) {
      padding: 1rem 2rem;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 1.5;
      margin: 0;
    }
  }

  &__body {
    min-height: 0;
    flex: 1 1 0%;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
    border-top: 1px solid $light-gray;

    @include media-breakpoint-up(sm) {
      padding: 1rem 2rem;
    }
  }

  &--sm {
    max-width: 600px;
    width: 100%;
  }

  &--md {
    max-width: 800px;
    width: 100%;
  }
}
