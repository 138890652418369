.subcategory {
  @include scrollbar-blue();
  &__heading {
    display: flex;
    gap: $gap-small;
    align-items: last baseline;
  }

  &__carousel {
    display: grid;
    gap: $gap-large;
    grid-auto-flow: column;
    grid-auto-columns: max-content;

    overflow-x: auto;

    scroll-snap-type: inline mandatory;
    scroll-padding-inline: $gap-large;
    scroll-behavior: smooth;

    @include media-breakpoint-down(md) {
      gap: $gap-medium;
    }
  }

  &__show-all {
    color: $primary;
    font-size: $text-1;
    line-height: $line-height-t1;
    cursor: pointer;

    &--disabled {
      @extend .subcategory__show-all;

      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
