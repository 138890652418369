.headlessui-autocomplete {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  width: 100%;

  &__actions {
    display: flex;
  }

  .headlessui-autocomplete-wrapper {
    position: relative;
  }

  &__input-wrapper {
    display: flex;
    width: 100%;
    position: relative;
  }

  &__input-container {
    flex: 1;
    display: flex;
    position: relative;
    border: none;
    border-right: none;
    align-items: center;
    padding-right: 18px;

    &__input {
      flex: 1;
      font-size: 18px;
      line-height: 30px;
      padding: 14px 32px 12px;
      background-color: transparent;
      color: $black;
      border: 2px solid #979797;
      display: inline-block;

      &:focus-visible {
        outline: none;
      }
    }

    &--icon--loading {
      color: $primary;

      scale: 2;
      height: 1rem;

      animation: spin 5s linear infinite;
    }
  }

  &__options {
    position: absolute;
    z-index: 1;
    max-height: 16rem;
    width: 100%;
    overflow-y: auto;
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    list-style: none;
    padding: 0;
  }

  &__option {
    cursor: pointer;
    display: flex;

    a {
      flex: 1;
      padding: 14px 32px 12px;
      color: $black;
      text-decoration: none;
    }
    span {
      padding: 14px 32px 12px;
    }

    &:hover,
    &:focus,
    &--active {
      background-color: $light-blue;
    }

    &--not-found {
      color: $bf-gray;
      cursor: default;
      pointer-events: none;
      // &:hover,
      // &:focus {
      //   background-color: inherit;
      //   color: inherit;
      //   cursor: default;
      // }
    }

    &--loading {
      color: $bf-gray;
      cursor: default;
      pointer-events: none;
    }

    &--hidden {
      height: 0;
      width: 0;
      overflow: hidden;
    }
  }

  &__transition {
    &--leave {
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      transition-duration: 100ms;
    }
    &--leave-from {
      opacity: 1;
    }
    &--leave-to {
      opacity: 0;
    }
  }

  &--disabled {
    .headlessui-listbox__button {
      color: $bf-gray;
      cursor: default;
      pointer-events: none;

      &::placeholder {
        color: $bf-gray;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &__actions {
      flex-direction: column;
      gap: $gap-medium;
    }
    &__input-container {
      border-right: 2px solid #979797;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
