.opportunity-not-found {
  width: clamp(5vw, 360px, 75vw);
  background-color: $light-blue;
  padding: $card-cap-padding-x * 2 $card-cap-padding-x;
  margin-bottom: $gap-large;

  h3 {
    text-transform: none;
  }
}
