.opportunity-skeleton {
  //   @include skeleton-animation();
  width: clamp(5vw, 360px, 75vw);
  margin-bottom: $gap-large;

  &__heading-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $gap-medium;

    &__content {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;

      &__opportunity-type {
        @include skeleton-animation();
        width: 100%;
        height: 1.5rem;
      }
    }

    &__image {
      @include skeleton-animation();
      width: 10.6rem;
      aspect-ratio: 4/3;
    }
  }

  &__country {
    @include skeleton-animation();
    width: 40%;
    height: 20px;
    margin: 1rem 0;
  }

  &__title {
    @include skeleton-animation();
    width: 100%;
    height: 2rem;
    margin-bottom: 2rem;
  }

  .multi-line {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
  }

  &__industry {
    @include skeleton-animation();
    width: 100%;
    height: $text-2;
  }
}
