.headless-transition {
  transform-origin: top;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 200ms;

  &:empty {
    visibility: hidden;
  }

  &[data-closed] {
    opacity: 0;
    transform: scale(0.9);
  }
}
