.market-opportunities {
  @import "headlessui/index";
  @import "pages/index";
  @import "components/index.scss";
  @import "form/index";

  .skeleton {
    @include skeleton-animation();

    &__button {
      @include skeleton-animation();
      width: 4rem;
      height: 2rem;
    }

    &__button-link {
      display: block;
      @include skeleton-animation();
      width: 100%;
      height: 1.5rem;
    }
  }

  form {
    display: contents;
  }
}
